/*======================
8. Repsonsive
========================*/

@media (max-width: 1500px) {
  .container-fluid {
    padding-left: 65px;
    padding-right: 65px;
  }

  .sigma_shapes.irregular .irregular-2 {
    display: none;
  }

  .sigma_banner-logo {
    width: 600px;
    height: 600px;
  }

  .sigma_banner-logo img {
    width: 150px;
  }

  .header-2 .sigma_header-controls.style-2,
  .header-2 .sigma_header-controls.style-1 a {
    padding: 0 20px;
  }

  .header-2 .sigma_header-middle .sigma_header-button {
    margin-right: 20px;
  }

  .header-2 .sigma_header-middle .navbar-nav {
    margin-left: 20px;
  }

  .sigma_header-contact h6,
  .sigma_header-contact span {
    font-size: 0;
  }

  .sigma_header-contact {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fdb900;
    color: #fff;
  }

  .header-4 .sigma_header-contact {
    width: 35px;
    height: 35px;
  }

  .sigma_header-contact:hover {
    color: #fff;
    background-color: #ca9300;
  }

  .sigma_header-contact i {
    font-size: 30px;
    margin-right: 0;
  }

  .header-4 .sigma_header-contact i {
    font-size: 20px;
  }

  .sigma_header-middle .navbar > .navbar-nav > .menu-item > a {
    padding: 30px 10px;
  }

  .header-2 .sigma_header-controls .sigma_btn-custom {
    margin-left: 20px;
  }

  .banner-3 .sigma_arrows .sigma_round-arrow svg {
    width: 50px;
  }

  .banner-3 .sigma_arrows .next-arrow {
    top: auto;
    transform: translateY(0);
    bottom: 40px;
  }

  .banner-3 .sigma_arrows .prev-arrow {
    top: auto;
    transform: translateY(0);
    bottom: 40px;
    right: 90px;
    left: auto;
  }
}

@media (max-width: 1199px) {


  .navbar-brand img.logo {
    width: 120px;
    height: 60px;
    margin: 0px;
    padding: 0px;
      }
  
        .sigma_footer.sigma_footer-dark .footer-widget ul  {
          margin-left: 250px;
          width: 100px;
          padding-right: 0px;
        }
  
      .sigma_footer.sigma_footer-dark .footer-widget p {
       padding-right: 50px;
       margin-left: 50px;
       width: 400px;
       font-size: 11px;
      }
  
      .sigma_footer-middle {
        margin-bottom: 50px;
      }

     .sigma_icon-block.icon-block-2 {
       font-size: 10px;
     }

     .sigma_footer.sigma_footer-dark .footer-widget ul li a{
        margin: 0px 0px 0px 0px;
      }
    
      .sigma_footer-middle img {
        width: 250px;
        height: 150px;
      }
    
      .sigma_btn-custom.secondary.submit {
        margin-left: 22rem;
      }
    
      .careersCTA {
        border-radius: 50px !important;
      }
    
      .logo {
        width: 3.1em;
        height: 3.4em;
      }

      .recaptcha { 
        transform: translate(31%, 10%);
      }

  .sigma_banner .title {
    font-size: 50px;
  }

  .sigma_auth-description {
    width: 450px;
  }

  .checkout-billing {
    margin-top: 50px;
  }

  .sigma_banner-logo {
    width: 500px;
    height: 500px;
  }

  .sigma_banner-logo img {
    width: 150px;
  }

  .header-2 .sigma_header-controls .sigma_btn-custom {
    display: block;
  }

  .header-2 .sigma_header-middle .navbar > .navbar-nav > .menu-item > a {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sigma_header.header-fw.header-2 .sigma_header-middle > .container {
    padding-right: 2vw;
  }

  .sigma_header-top,
  .sigma_header-inner .sigma_header-top {
    display: block;
  }

  .sigma_header-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sigma_header-inner .navbar-nav,
  .sigma_header-inner .sigma_header-top {
    padding: 0;
  }

  .header-3 .sigma_logo-wrapper .navbar-brand {
    width: 150px;
  }
}

@media (max-width: 991px) {

  .navbar-brand img.logo {
    width: 120px;
    height: 60px;
    margin: 0px;
    padding: 0px;
      }
  
        .sigma_footer.sigma_footer-dark .footer-widget ul  {
          margin-left: 10px;
        }
  
      .sigma_footer.sigma_footer-dark .footer-widget p {
       padding-left: 0px;
       margin-left: 0px;
       width: 300px;
      }
  
      .sigma_footer-middle {
        margin-bottom: 50px;
      }
    
     .sigma_footer.sigma_footer-dark .footer-widget ul li a{
        margin: 0px 0px 0px 100px;
        width: 100px;
      }
    
      .sigma_footer-middle img {
        width: 250px;
        height: 150px;
      }
    
      .sigma_btn-custom.secondary.submit {
        margin-left: 15rem;
      }
    
      .careersCTA {
        border-radius: 50px !important;
      }
    
      .logo {
        width: 3.1em;
        height: 3.4em;
      }

      .recaptcha { 
        transform: translate(24%, 10%);
      }

  .circle-2 {
    display: none;
  }

  .circle-3 {
    display: none;
  }

  .circle-4 {
    display: none;
  }

  .logo {
    width: 3.1em;
    height: 3.4em;
  }

  .mt-20,
  .mt-n-20 {
    margin: 0;
  }

  .header-2 .sigma_logo-wrapper {
    height: auto;
    margin-bottom: 0;
    box-shadow: none;
    padding: 0 20px;
  }

  .header-2 .sigma_header-controls ul li:not(.aside-trigger-left) {
    display: none;
  }

  .sigma_header-contact {
    width: 40px;
    height: 40px;
  }

  .sigma_header-contact i {
    font-size: 20px;
  }

  .sigma_banner-logo {
    position: relative;
    transform: translateY(0);
    top: auto;
    left: auto;
    padding: 0;
    margin: 0 auto 40px;
    width: 250px;
    height: 250px;
  }

  .sigma_banner-logo img {
    width: 100px;
  }

  .sigma_banner-logo .sigma_sm,
  .sigma_banner-logo::before {
    display: none;
  }

  .sigma_box-absolute {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
  }

  .mt-negative-sm + .mt-negative-sm,
  .mt-negative-sm {
    margin-top: 0;
  }

  .subheader-toggler,
  .sigma_subheader-extras {
    display: none;
  }

  .banner-3.sigma_banner .sigma_banner-slider-inner {
    padding: 190px 0 210px;
  }

  .header-absolute + .banner-3.sigma_banner .sigma_banner-slider-inner {
    padding: 190px 0 160px;
  }

  .banner-2.sigma_banner .sigma_banner-slider-inner {
    padding: 220px 0 170px;
  }

  .sigma_contact-bg {
    height: 400px;
    margin-top: 80px;
  }

  .sigma_contact-info {
    position: relative;
    bottom: auto;
    left: auto;
    height: auto;
  }

  .sigma_map .sigma_contact-info {
    right: auto;
  }

  .sigma_map {
    height: auto;
  }

  .sigma_map iframe {
    height: 300px;
  }

  .banner-2.sigma_banner .title,
  .section-title .title {
    font-size: 40px;
  }

  .section-title {
    margin-bottom: 30px;
  }

  .section-title.text-center {
    margin: 0 auto 30px;
  }

  .sigma_shapes.circles,
  .sigma_grid-slider .slick-prev,
  .sigma_grid-slider .slick-next {
    display: none;
  }

  .sigma_subheader h1 {
    font-size: 40px;
  }

  .sigma_subheader .breadcrumb .breadcrumb-item.active,
  .sigma_subheader .breadcrumb li a {
    font-size: 12px;
  }

  .banner-1.sigma_banner .sigma_banner-slider-inner {
    padding: 140px 0 50px;
  }

  .sigma_banner .sigma_banner-slider-inner .sigma_banner-text {
    padding: 0 15px;
  }

  .banner-1.sigma_banner
    .sigma_banner-slider-inner
    .sigma_video-popup-wrap
    img {
    margin: 0 auto;
  }

  .sigma_banner .title {
    font-size: 40px;
  }

  .sigma_banner.banner-1 .sigma_arrows,
  .sigma_banner.banner-3 .sigma_arrows {
    right: 50%;
    transform: translateX(50%);
    bottom: 20px;
  }

  .sigma_banner.banner-1 .sigma_arrows::before {
    display: none;
  }

  .banner-3.sigma_banner .sigma_arrows {
    margin-top: 10px;
  }

  .mb-lg-20 {
    margin-bottom: 20px;
  }

  .mb-lg-30,
  .sigma_upsells {
    margin-bottom: 30px;
  }

  .mr-lg-30 {
    margin-left: 0;
  }

  .section {
    padding: 70px 0;
  }

  .section.mt-negative,
  .section.section-padding.mt-negative,
  .mt-negative {
    margin-top: 0;
    padding-top: 70px;
  }

  .section-button {
    margin-top: 20px;
  }

  .section.section-padding {
    padding: 70px 0 40px;
  }

  .section.section-lg {
    padding: 70px 0 130px;
  }

  .sigma_section-fw {
    padding: 0 15px;
  }

  .navbar-brand {
    width: 180px;
  }

  .sigma_header-controls .aside-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #efefef;
    width: 58px;
    height: 58px;
    border-radius: 50%;
  }

  .sigma_header-bottom,
  .sigma_header-bottom .navbar-nav,
  .sigma_header-middle .navbar-nav,
  .sigma_header .sigma_search-adv {
    display: none;
  }

  .sigma_header-controls.style-2 .aside-toggler.style-2.desktop-toggler,
  .aside-toggler.style-2.desktop-toggler {
    display: none;
  }

  .sigma_header-controls.style-2 .aside-toggler.style-2,
  .aside-toggler.style-2 {
    display: grid;
  }

  .aside-toggler {
    display: block;
  }

  .sigma_aside-overlay.aside-trigger-left,
  .sigma_aside.sigma_aside-left {
    display: block;
  }

  .sigma_post-single .sigma_post-share {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .sidebar {
    margin-top: 40px;
  }

  .sidebar .sidebar-widget:last-child {
    margin-bottom: 40px;
  }

  .load-more {
    margin: 40px 0 30px;
  }

  .pagination {
    margin: 10px 0 30px;
  }

  .sigma_shapes.irregular .irregular-1,
  .sigma_icon-block svg {
    display: none;
  }

  .header-absolute + .sigma_subheader .sigma_subheader-inner {
    padding: 140px 0 80px;
  }

  .sigma_footer-top .container {
    flex-direction: column;
    text-align: center;
  }

  .sigma_footer.footer-2 .sigma_footer-top-item + .sigma_footer-top-item {
    border-left: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }

  .sigma_footer.footer-2 .sigma_footer-top-item {
    padding: 15px 0;
    width: 100%;
  }

  .sigma_footer-buttons {
    justify-content: center;
  }

  .sigma_footer.footer-2 .sigma_footer-top-item,
  .sigma_footer.footer-2 .sigma_footer-sm {
    justify-content: center;
  }

  .sigma_footer-bottom {
    padding: 20px 0;
  }

  .sigma_footer-bottom .sigma_footer-logo {
    display: none;
  }

  .sigma_footer-bottom .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .sigma_instagram {
    max-width: 400px;
    padding: 40px;
  }

  .sigma_product-buttons {
    display: block;
  }

  .sigma_product-variation-wrapper {
    margin-bottom: 20px;
  }

  .sigma_product-variation-wrapper,
  .sigma_product-buttons a {
    display: block;
  }

  .sigma_product-buttons a + a {
    margin-top: 20px;
    margin-left: 0;
  }

  .qty {
    justify-content: space-between;
  }

  .sigma_product-atc-form .qty-inner {
    margin-left: 0;
    display: block;
    width: 100%;
  }

  .sigma_product-variation-wrapper .form-group + .form-group {
    margin-left: 0;
  }

  .sigma_auth-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .sigma_auth-description {
    width: 100%;
  }

  .sigma_companies .sigma_companies-item {
    min-height: 110px;
  }

  .sigma_expaneded-row {
    flex-wrap: wrap;
  }

  .sigma_post-single-thumb .sigma_box {
    position: relative;
    top: auto;
    right: auto;
  }

  .sigma_portfolio-section .sigma_arrows {
    display: none;
  }

  .sigma_portfolio-slider .slick-slide {
    margin: 0 15px;
  }

  .sigma_portfolio-slider .slick-list {
    margin: 0 -15px;
  }

  .sigma_portfolio-slider .section {
    padding: 0 0 10px;
  }

  .sigma_portfolio-slider .portfolio-item .section-title .title {
    font-size: 26px;
  }

  .sigma_portfolio-slider .portfolio-item .section-title .subtitle {
    font-size: 14px;
  }
}

@media (max-width: 820px) {

  .navbar-brand img.logo {
    width: 120px;
    height: 60px;
    margin: 0px;
    padding: 0px;
      }
    
     .sigma_footer.sigma_footer-dark .footer-widget ul li a {
        margin: 0px;
        margin-left: 285px;
        text-align: center;
      }

      .sigma_footer.sigma_footer-dark .footer-widget p {
        margin: 0px;
        margin-left: 220px;
      }
    
    
      .sigma_footer-middle img {
        width: 250px;
        height: 150px;
        margin-left: 25%;
      }
    
      .sigma_btn-custom.secondary.submit {
        margin-left: 6rem;
      }
    
      .careersCTA {
        border-radius: 50px !important;
      }
    
      .logo {
        width: 3.1em;
        height: 3.4em;
      }
    }

@media (max-width: 768px) {
  .navbar-brand img.logo {
    width: 120px;
    height: 60px;
    margin: 0px;
    padding: 0px;
      }
    
     .sigma_footer.sigma_footer-dark .footer-widget ul li a{
      margin: 0px 0px 0px 190px;
      width: 100%;
      }

      .sigma_footer.sigma_footer-dark .footer-widget ul  {
        margin-left: 15%;
        text-align: center;
      }

    .sigma_footer.sigma_footer-dark .footer-widget p {
     padding-left: 0px;
     margin-left: 25%;
     margin-right: 10%;
     margin-bottom: 10%;
     width: 100%;
    }

    .sigma_footer-middle {
      margin-bottom: 50px;
    }
    
      .sigma_footer-middle img {
        width: 40%;
        height: 60%;
        margin-right: 45%;
        padding: 0px 20px 0px 0px;
      }
    
      .sigma_btn-custom.secondary.submit {
        margin-left: 14rem;
      }

      .recaptcha { 
        transform: translate(23%, 10%);
      }
    
      .careersCTA {
        border-radius: 50px !important;
      }
    
      .logo {
        width: 3.1em;
        height: 3.4em;
      }

  .circle-2 {
    display: none;
  }

  .circle-3 {
    display: none;
  }

  .circle-4 {
    display: none;
  }

  .logo {
    width: 3.1em;
    height: 3.4em;
  }

  .sigma_subheader > .container-fluid,
  .sigma_shapes.irregular .irregular-3,
  .sigma_img-custom::before,
  .texture-3 {
    display: none;
  }

  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  blockquote {
    font-size: 14px;
  }

  blockquote p {
    font-size: 14px;
  }

  h1 {
    font-size: 34px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 22px;
  }

  .section-title .title {
    font-size: 30px;
  }

  .sigma_banner .title {
    font-size: 35px;
  }

  .sigma_subheader h1 {
    font-size: 60px;
  }

  .sigma_subheader .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 5px;
  }

  .sigma_subheader .breadcrumb-item + .breadcrumb-item {
    padding-left: 5px;
  }

  .sigma_responsive-table thead {
    display: none;
  }

  .header-absolute + .sigma_subheader .sigma_subheader-inner {
    padding: 140px 0 50px;
  }

  .header-absolute + .sigma_subheader.subheader-lg .sigma_subheader-inner {
    padding: 190px 0 230px;
  }

  .sigma_shapes.waves .wave {
    top: -138px;
    height: 138px;
  }

  .sigma_shapes.waves .wave:nth-of-type(2) {
    top: -105px;
  }

  .sigma_responsive-table td {
    position: relative;
    display: block;
    width: 100%;
    text-align: right;
    padding: 10px;
    border-bottom: 0;
  }

  .sigma_responsive-table tr.total td::before {
    display: none;
  }

  .sigma_responsive-table tr.total td:first-child {
    border-top: 0;
    border-right: 0;
  }

  .sigma_responsive-table tr.total td:last-child {
    border-left: 0;
    border-top: 0;
  }

  .sigma_responsive-table tr.total td:nth-child(2) {
    display: none;
  }

  .sigma_responsive-table tr.total td {
    width: auto;
    text-align: left;
  }

  .sigma_responsive-table tr.total {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .sigma_responsive-table td::before {
    content: attr(data-title) ": ";
    font-weight: 700;
    float: left;
  }

  .sigma_responsive-table td.remove::before {
    display: none;
  }

  .sigma_responsive-table tr {
    border-bottom: 1px solid #efefef;
  }

  .sigma_quick-view-modal img {
    margin-bottom: 30px;
  }

  .footer-widget {
    text-align: center;
    padding-bottom: 50px;
  }

  .sigma_footer .footer-widget p {
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 40px !important;
  }

  .sigma_footer.footer-2 .sigma_footer-middle {
    padding-top: 50px;
  }

  .footer-widget .sigma_sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .sigma_footer-buttons a {
    margin-bottom: 5px;
  }

  .section-title .filter-items {
    width: 100%;
    margin: 20px 0 0;
  }

  .footer-widget ul li a {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
  }

  .footer-widget ul li a::before {
    display: none;
  }

  .sigma_footer-top {
    display: block;
    text-align: center;
  }

  .sigma_footer-logo {
    margin: 0 auto 20px;
  }

  .sigma_footer.footer-2 .sigma_footer-top {
    padding: 35px 0;
  }

  .sigma_footer-bottom .sigma_footer-copyright {
    display: block;
    text-align: center;
    margin-bottom: 15px;
  }

  .sigma_footer-bottom .sigma_footer-copyright > a {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 20px;
  }

  .sigma_subheader-inner {
    display: block;
  }

  .sigma_subheader .breadcrumb {
    margin-top: 20px;
  }

  .sigma_header-top-links {
    justify-content: center;
    padding: 10px 0;
  }

  .sigma_header-top-links a {
    padding: 0;
  }

  .sigma_header-top-links > li + li > a {
    padding-left: 10px;
    margin-left: 10px;
    border-left: 0;
  }

  .sigma_header-top-cta {
    text-align: center;
  }

  .sigma_header-top-cta a {
    display: inline-block;
  }

  .sigma_counter {
    text-align: center;
  }

  .filter-items {
    display: block;
    border-bottom: 0;
    margin-bottom: 30px;
  }

  .filter-items h5 + h5::before {
    display: none;
  }

  .sigma_author-about {
    display: block;
    padding: 0;
    border: 0;
  }

  .sigma_author-about img {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .sigma_post-single-thumb .sigma_icon-block {
    position: relative;
    margin: 0;
    max-width: 100%;
    top: auto;
    left: auto;
    transform: translate(0);
  }
}

@media (max-width: 740px) {

  .navbar-brand img.logo {
    width: 120px;
    height: 60px;
    margin: 0px;
    padding: 0px;
      }
    
     .sigma_footer.sigma_footer-dark .footer-widget ul li a{
        margin: 0px;
        margin-left: 120px;
      }

      .sigma_footer.sigma_footer-dark .footer-widget p{
        margin: 0px;
        margin-left: 70px;
      }
    
    
      .sigma_footer-middle img {
        width: 250px;
        height: 110px;
        margin-left: 80%;
      }
    
      .sigma_btn-custom.secondary.submit {
        margin-left: 6rem;
      }
    
      .careersCTA {
        border-radius: 50px !important;
      }
    
      .logo {
        width: 3.1em;
        height: 3.4em;
      }
    }


@media (max-width: 670px) {

  .navbar-brand img.logo {
    width: 120px;
    height: 60px;
    margin: 0px;
    padding: 0px;
      }
    
     .sigma_footer.sigma_footer-dark .footer-widget ul li a{
        margin: 0px;
        margin-left: 130px;
      }

      .sigma_footer.sigma_footer-dark .footer-widget p{
        margin: 0px;
        margin-left: 80px;
      }
    
    
      .sigma_footer-middle img {
        width: 250px;
        height: 100px;
        margin-left: 87%;
      }
    
      .sigma_btn-custom.secondary.submit {
        margin-left: 6rem;
      }
    
      .careersCTA {
        border-radius: 50px !important;
      }
    
      .logo {
        width: 3.1em;
        height: 3.4em;
      }
    }


@media (max-width: 575px) {

  .navbar-brand img.logo {
    width: 120px;
    height: 60px;
    margin: 0px;
    padding: 0px;
      }
    
     .sigma_footer.sigma_footer-dark .footer-widget ul li a{
        margin: 0px;
        margin-left: 140px;
      }

      .sigma_footer.sigma_footer-dark .footer-widget p{
        margin: 0px;
        margin-left: 70px;
      }
    
    
      .sigma_footer-middle img {
        width: 250px;
        height: 150px;
        margin-left: 0px;
      }
    
      .sigma_btn-custom.secondary.submit {
        margin-left: 6rem;
      }
    
      .careersCTA {
        border-radius: 50px !important;
      }
    
      .logo {
        width: 3.1em;
        height: 3.4em;
      }

  .history2 {
    height: 200px;
  }

  .circle-2 {
    display: none;
  }

  .circle-3 {
    display: none;
  }

  .circle-4 {
    display: none;
  }

  .logo {
    width: 3.1em;
    height: 3.4em;
  }

  blockquote {
    padding: 20px;
  }

  blockquote::before {
    display: none;
  }

  .entry-content blockquote p,
  blockquote p {
    font-size: 14px;
  }

  .sigma_sm.square li a {
    width: 35px;
    height: 35px;
  }

  .sigma_subheader h1 {
    font-size: 40px;
  }

  .sigma_shapes.waves {
    display: none;
  }

  .header-absolute + .sigma_subheader.subheader-lg .sigma_subheader-inner {
    padding: 160px 0 100px;
  }

  .sigma_header.header-4 .sigma_header-top {
    display: none;
  }

  .sigma_header.header-4 .sigma_header-middle {
    margin-top: 15px;
  }

  .sigma_btn-custom {
    padding: 8px 18px;
    font-size: 13px;
  }

  .btn-link {
    font-size: 13px;
  }

  .banner-2.sigma_banner p {
    font-size: 14px;
  }

  .banner-1.sigma_banner .sigma_banner-slider-inner {
    padding: 110px 0 40px;
  }

  .sigma_post-categories a {
    padding: 4px 15px;
  }

  .sigma_post.sigma_post-list .sigma_post-body {
    padding: 20px;
  }

  .sigma_post.sigma_post-list .sigma_post-footer {
    margin-top: 15px;
  }

  .sigma_post.sigma_post-list .sigma_post-meta,
  .sigma_post.sigma_post-list .sigma_post-meta a {
    display: block;
  }

  .sigma_post.sigma_post-list .sigma_post-categories a {
    display: inline-block;
  }

  .sigma_post.sigma_post-list .sigma_post-meta a + a {
    margin-left: 0;
    margin-top: 10px;
  }

  .sigma_post.sigma_post-list .sigma_post-body h5 {
    font-size: 22px;
  }

  .sigma_post.post-format-quote .sigma_post-body > i {
    display: none;
  }

  .comments-list .comment-item .btn-link {
    position: relative;
    top: auto;
    right: auto;
  }

  .post-detail-wrapper {
    padding: 0;
    border: 0;
  }

  .header-2 .sigma_logo-wrapper {
    padding: 0 15px;
  }

  .aside-toggler.style-2:hover span,
  .aside-toggler.style-2 span {
    width: 5px;
    height: 5px;
  }

  .sigma_header-top-links > li + li > a {
    padding-left: 5px;
    margin-left: 5px;
  }

  .header-2 .sigma_header-controls.style-2,
  .header-2 .sigma_header-controls.style-1 a {
    padding: 0 10px;
  }

  .header-2 .sigma_header-middle .navbar {
    padding: 10px 0;
  }

  .sigma_header.header-fw.header-2 .sigma_header-middle > .container {
    padding: 0 10px;
  }

  .navbar-brand {
    width: 120px;
  }

  .sigma_header-top-links a {
    font-size: 9px;
    padding-right: 20px;
  }

  .sigma_banner .title {
    font-size: 30px;
  }

  .banner-3.sigma_banner
    .sigma_banner-slider-inner
    .header-absolute
    + .banner-3.sigma_banner
    .sigma_banner-slider-inner {
    padding: 140px 0 120px;
  }

  .banner-2.sigma_banner .sigma_banner-slider-inner {
    padding: 140px 0 110px;
  }

  .section-title.flex-title .nav {
    width: 100%;
    margin: 20px 0 0;
  }

  .modal-body {
    padding: 20px;
  }

  .sigma_product-controls {
    opacity: 1;
    visibility: visible;
  }

  .sigma_product-atc-form .qty-outter .sigma_btn-custom + .qty {
    margin-left: 0;
    margin-top: 20px;
  }

  .sigma_product-additional-info .nav {
    flex-direction: column;
  }

  .sigma_product-additional-info .nav-item + .nav-item .nav-link {
    margin-left: 0;
    margin-top: 10px;
  }

  .sigma_cta-notice {
    padding: 20px;
  }

  .sigma_cta h4 {
    font-size: 28px;
  }

  .sigma_header-cart-content,
  .sigma_header-controls-inner li.sigma_header-favorites {
    display: none;
  }

  .sigma_header-controls-inner li.sigma_header-cart a,
  .sigma_header-controls-inner li a,
  .sigma_header-controls .aside-toggler {
    padding: 0;
  }

  .sigma_header-controls-inner li.sigma_header-cart a i {
    font-size: 21px;
  }

  .sigma_shop-global {
    display: block;
  }

  .sigma_shop-global p {
    margin-bottom: 20px;
  }

  .comments-list .comment-item span {
    position: relative;
    top: auto;
    right: auto;
    display: block;
  }

  .comment-form {
    padding: 20px;
  }

  .sigma_experience-item {
    display: block;
  }

  .sigma_experience-item strong {
    display: block;
  }

  .comments-list .comment-item img {
    width: 45px;
    margin-right: 15px;
  }

  .sigma_post-single-meta {
    display: block;
  }

  .sigma_product-single-content .sigma_post-single-meta {
    display: flex;
  }

  .sigma_post-single-meta-item + .sigma_post-single-meta-item {
    margin-top: 20px;
    text-align: left;
  }

  .sigma_post-single-meta-item.sigma_post-share .sigma_sm {
    justify-content: flex-start;
  }

  .sigma_auth-form {
    padding: 30px;
  }

  .sigma_cta-content p {
    margin-bottom: 10px;
    max-width: 100%;
  }

  .sigma_header-top-sm li a {
    font-size: 13px;
  }

  .sigma_header-top-sm li a i {
    display: none;
  }

  .sigma_image-wrap .sigma_image-box .sigma_video-box .sigma_video_wrapper,
  .sigma_image-wrap .sigma_image-box .sigma_video-box {
    width: 270px;
  }

  .sigma_testimonial.sigma_box .slick-dots {
    position: relative;
    margin: 20px 0 0;
  }

  .sigma_testimonial .sigma_testimonial-inner > p {
    margin: 0 0 20px;
  }

  .sigma_testimonial.sigma_box {
    padding: 30px;
  }

  .absolute-img {
    width: 100%;
  }

  .sigma_search-form-wrapper form {
    width: 100%;
  }

  .sigma_search-form-wrapper form input {
    font-size: 30px;
    padding: 10px 0;
  }

  .sigma_search-form-wrapper .sigma_search-btn {
    font-size: 25px;
  }

  .sigma_search-form-wrapper .close-btn {
    top: 20px;
    right: 20px;
  }

  .sigma_footer-bottom .container-fluid {
    flex-direction: column;
  }

  .sigma_list.list-2 {
    columns: 1;
  }

  .sigma_pricing.pricing-3 .sigma_pricing-info {
    padding: 80px 20px 40px;
  }

  .sigma_pricing.pricing-3 .sigma_pricing-meta {
    left: 20px;
    width: calc(100% - 40px);
  }

  .blockquote {
    display: block;
    text-align: center;
  }

  .blockquote .sigma_avatar {
    margin: 0 auto;
  }

  .sigma_blockquote-content p {
    font-size: 14px;
  }

  .sigma_blockquote-content {
    margin-left: 0;
  }

  .sigma_single-pagination {
    display: block;
  }

  .sigma_single-pagination-prev,
  .sigma_single-pagination-next {
    border-radius: 25px;
  }

  .sigma_single-pagination-item + .sigma_single-pagination-item {
    margin-top: 20px;
  }
}

@media (max-width: 420px) {

  .navbar-brand img.logo {
    width: 120px;
    height: 60px;
    margin: 0px;
    padding: 0px;
      }
    
     .sigma_footer.sigma_footer-dark .footer-widget ul li a{
        margin: 0px;
        margin-left: 80px;
      }

      .sigma_footer.sigma_footer-dark .footer-widget p{
        margin: 0px;
        margin-left: 25px;
      }
    
    
      .sigma_footer-middle img {
        width: 250px;
        height: 150px;
        margin-left: 75px;
      }
    
      .sigma_btn-custom.secondary.submit {
        margin-left: 6rem;
      }
    
      .careersCTA {
        border-radius: 50px !important;
      }
    
      .logo {
        width: 3.1em;
        height: 3.4em;
      }
    }

@media (max-width: 400px) {
  .navbar-brand img.logo {
    width: 120px;
    height: 60px;
    margin: 0px;
    padding: 0px;
      }
  
        .sigma_footer.sigma_footer-dark .footer-widget ul  {
          margin-left: 120px;
          width: 100px;
          padding-right: 0px;
        }
  
      .sigma_footer.sigma_footer-dark .footer-widget p {
       padding-right: 60px;
       margin-left: 0px;
       width: 400px;
       font-size: 11px;
      }
  
      .sigma_footer-middle {
        margin-bottom: 50px;
      }

     .sigma_icon-block.icon-block-2 {
       font-size: 10px;
     }

     .sigma_footer.sigma_footer-dark .footer-widget ul li a{
        margin: 0px 20px 0px 0px;
        padding: 0px 0px 0px 0px;
      }
    
      .sigma_footer-middle img {
        width: 250px;
        height: 150px;
        margin-left: 50px;
      }
    
      .sigma_btn-custom.secondary.submit {
        margin-left: 22rem;
      }
    
      .careersCTA {
        border-radius: 50px !important;
      }
    
      .logo {
        width: 3.1em;
        height: 3.4em;
      }

      .recaptcha { 
        transform: translate(-10%, 10%);
      }

  .history2 {
    height: 200px;
  }

  .circle-2 {
    display: none;
  }

  .circle-3 {
    display: none;
  }

  .circle-4 {
    display: none;
  }

  .navbar-brand img.logo {
width: 120px;
height: 60px;
margin: 0px;
padding: 0px;
  }

 .sigma_footer.sigma_footer-dark .footer-widget ul li a{
    margin: 0px;
  }

  .sigma_footer-middle img {
    width: 250px;
    height: 150px;
  }

  .sigma_btn-custom.secondary.submit {
    margin-left: 4.5rem;
  }

  .careersCTA {
    border-radius: 50px !important;
  }

  .logo {
    width: 3.1em;
    height: 3.4em;
  }

  .img-group img:nth-child(3),
  .img-group img:nth-child(2) {
    display: none;
  }

  .img-group {
    padding: 0;
  }
}
