.loadingDiv {
  background-color: rgba(255, 187, 0, 0);
  margin: 20% 0% 0% 20%;
  height: 500px;
  width: 850px;
  border-radius: 100px;
}

.cogimg {
  margin: 15% 0% 0% 45%;
  height: 200px;
  width: 200px;
}

@media (prefers-reduced-motion: no-preference) {
  .cogimg {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 991px) {
  .cogimg {
    margin: 30% 0% 0% 37%;
    height: 200px;
    width: 200px;
  }
}

@media (max-width: 768px) {
  .cogimg {
    margin: 30% 0% 0% 37%;
    height: 200px;
    width: 200px;
  }
}

@media (max-width: 575px) {
  .cogimg {
    margin: 30% 0% 0% 23%;
    height: 200px;
    width: 200px;
  }
}

@media (max-width: 400px) {
  .cogimg {
    margin: 30% 0% 0% 23%;
    height: 200px;
    width: 200px;
  }
}
